.MedBtn-btn:hover {
  color: white !important;
  text-decoration: none;
}

.forParentMed input {
  width: 100%;
}

.newPrescriptionChecks .ant-input-number-input::placeholder{
  color: #959494;
/* text-align: left; */
font-family: "Circular Std";
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

.newPrescriptionChecks input {
  background-color: #fff;
  border: 1px solid #d0d0d0 !important;
  border-radius: 12px;
  line-height: 42px;
  margin-top: 6px;
  padding-left: 9px;
}

.add-custom-med {
  background-color: #19B3B5;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer !important;
}