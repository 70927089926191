.cancelMedCard {
  width: auto;
  padding-right: 0;
  margin-bottom: 10px;

  .pink_div {
    background-color: #29BCC1;
    border-radius: 7px;
    padding: 10px 18px 10px 15px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;

    .heading_desc_small {
      font-weight: 700;
      color: #fff !important;
    }
  }
}