.sideBar {
  background: #ffffff;
  height: auto;
  padding: 5px 0 10px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 9;

  &:focus {
    outline: none;
  }

  .flex_center {
    width: 77%;

    .heading_desc {
      padding-top: 5px;
      font-weight: 700;
    }
  }

  .open_sidebar {
    background: transparent;
    border: none;
    -webkit-appearance: none;
    box-shadow: none;
    padding: 15px 15px 5px;
    cursor: pointer;
  }
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    width: 80% !important;
    height: 100vh;
  }

  .ant-menu {
    &.ant-menu-root {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      height: 100vh;
      background: #f7f7f7;
    }

    &.ant-menu-inline-collapsed {
      display: none;
    }

    .ant-menu-item {
      height: auto;
      padding: 0;
      padding-left: 0 !important;

      &:hover,
      &:active,
      &.ant-menu-item-selected,
      &:after {
        background: #f7f7f7;
        border-right: none;
      }

      .heading_desc_small {
        font-weight: 700;
        font-size: 1rem;
      }

      .ant-menu-title-content {
        padding: 10px 20px 15px;
        border-bottom: 1px solid #ebebeb;

        .flex_start {
          gap: 20px;

          img {
            width: 18px;
          }
        }
      }

      &:first-child {
        height: auto;

        .sideProfile {
          padding: 40px 20px 40px 0;

          img {
            width: 50px;
            height: 50px;
          }

          .headingDescVsmall {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(8) {
        position: absolute;
        bottom: 100px;

        .ant-menu-title-content {
          border-bottom: none;
        }
      }

      &:last-child {
        // position: absolute;
        // bottom: 40px;

        .ant-menu-title-content {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}



@media (min-width:0px) and (max-width:767px) {
  #loginDropdown .dropDownTitle h5.heading_desc_small {
    color: #fff;
  }


  .sideProfile.mobilemenu h5.heading_desc_small {
    font-family: 'Nunito';
  }

  .ant-drawer .ant-menu .ant-menu-item:first-child .sideProfile {
    padding: 20px 0px 20px 0;
  }

  .ant-drawer .ant-menu.ant-menu-root {

    background: #ffffff;
  }

  .sidebar_menu .heading_desc_small {

    font-family: 'Nunito';
    font-size: 16px;
  }

  .icon_dropdown01 {
    position: absolute;
    width: 11px !important;
    right: 30px;
  }

  .close_icon {
    border: 0.5px solid rgba(49, 49, 49, 0.4);
    border-radius: 3px;
    width: 35px;
    height: 35px;
    text-align: center;
    position: absolute;
    line-height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    padding: 0;
  }

  .close_icon img {
    width: 16px !important;
    height: 16px !important;
  }

  .modal_logout {
    /* background: #FFFFFF; */
    box-shadow: 0px -2.83333px 7.55556px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
  }
  .modal_logout.modal-centerd-screen{
    top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    max-width: 100%;
  }

  .modal_logout .ant-modal-header {
    padding: 0;
    border: 0px;
  }

  .modal_logout .ant-modal-body {
    text-align: center;
    padding: 50px 24px;
    border-radius: 10px !important;
  }

  .modal_logout .ant-modal-footer {
    display: none;
  }

  .switchModalBtns {
    border: 0.5px solid #31313140;
    border-radius: 5px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    padding: 0px 46px;
    letter-spacing: 0.05em;
    color: #313131;
    height: 43px;
  }

  .sidebar_menu h5.heading_desc_small {
    font-size: 15px !important;
  }

  .sidebar_menu span.ant-menu-title-content {
    background: #fff;
  }

  button.ant-btn.ant-btn-default.btn.btn-primary.switchModalBtns.ms-2 {
    background: #29BCC1;
    border: 1px solid #29BCC1;
    color: #fff;
  }

  .modal_logout .ant-modal-body p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #313131;
    margin-bottom: 38px;
  }

  .modal_logout .ant-modal-content {
    border-radius: 10px !important;
  }

  .column_flex.box001 {
    width: 173px;
    display: block;
    left: -10px;

    position: relative;
  }

  .box001 h5.heading_desc_small {
    white-space: break-spaces;
  }
}