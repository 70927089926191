.headingWithSpaceLarge {
  color: #313131;
  font-family: "Circular Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

span.MuiTabs-indicator.css-ttwr4n {
  display: none;
}