.fewMoreDetail .dateOfBirth,
.fewMoreDetail .sizeContainer {
  display: flex;
}

.fewMoreDetail .dateOfBirth>div:nth-child(-n + 2),
.fewMoreDetail .sizeContainer>div:first-child {
  margin-right: 10px;
}

.fewMoreDetail .dateOfBirth>div {
  width: 100%;
}

.fewMoreDetail .sizeContainer,
.fewMoreDetail .dateOfBirth,
.fewMoreDetail .customSelect2 {
  margin-bottom: 20px;
}

.fewMoreDetail .content_container {
  margin-top: 5rem;
}

.fewMoreDetail .form {
  margin-top: 20px;
}

.underline_ancer1 {
  font-size: .938rem;
  font-weight: 700;
  letter-spacing: .094rem;
  max-width: max-content;
  position: relative;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  width: 100%;
  color: #EF6286;
  display: inline-block;
}

.underline_ancer1:hover {
  color: #EF6286;
}

.underline_ancer1::after {
  background: none repeat scroll 0 0 transparent;
  background-color: #EF6286;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  max-height: 0.5px;
  position: absolute;
  transition: width .3s ease 0s, left .3s ease 0s;
  width: 0;
}

.underline_ancer1:hover::after {
  width: 100%;
  left: -1px;
}

.errorRequired {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #BC0001;
  margin-bottom: 5px;
}

.specialityPlaceholder {
  font-family: 'Circular Std';
  font-weight: 300;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #959494;

}

.terms-privacy .termsConditions {
  font-family: Circular Std;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #E9406A;
}

.selectSpeciality .ant-select {
  border: 0.5px solid #959494;
  border-radius: 10px;
  padding: 4px;
  margin: 5px 0;
}

.selectSpeciality .ant-select-selection-overflow {
  min-height: 20px;
  max-height: 70px;
  overflow-y: auto;
}

.selectSpeciality .ant-select-selection-overflow::-webkit-scrollbar {
  width: 0em;
}

.selectSpeciality .ant-select-selection-overflow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.selectSpeciality .ant-select-selection-overflow::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.selectSpeciality {
  margin-bottom: 0 !important;
}

/* .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected .ant-select-item-option-content{
  color: #fff !important;
} */
.ant-select-item.ant-select-item-option:hover .ant-select-item-option-content,
.ant-select-item.ant-select-item-option.ant-select-item-option-selected .ant-select-item-option-content {
  /* color: #fff !important; */
}


.detailssForm .formHead .labelHead {
  font-family: Circular Std;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.58px;
  color: #313131;
}

.detailssForm .formHead .detailFormfield::placeholder {
  font-family: Circular Std;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.58px;
  color: #959494;
}

.detailssForm .formHead .ant-select-multiple .ant-select-selection-placeholder,
.citySelectSpe .ant-select-selection-placeholder{
  font-family: Circular Std;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.58px;
  color: #959494;
}

.detailssForm .details-select .details-select-option {
  font-family: Circular Std;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.58px;
  color: #959494;
}

.selectParent select {
  display: block;
  width: 100%;
  padding: 2px 2px 2px 2px;
  border: none;
  background: url("../../assets/images/svg/arrowIcon.svg") calc(100% - 10px) center no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.selectParent.left select {
  direction: rtl;
  padding: 2px 2px 2px 25px;
  background-position: 20px center;
}

/* for IE and Edge */
select::-ms-expand {
  display: none;
}
.citySelectSpe .ant-select-selector{
  height: 30px !important;
}
.placeholder-left .ant-select-selection-placeholder{
  left: 6px;
}
.emailNewInput .ant-row.ant-form-item{
  margin-bottom: 0;
}
.emailNewInput .ant-row.ant-form-item{
  margin-bottom: 0;
}
.emailNewInput input.ant-input{
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0.5px solid #959494;
  border-radius: 10px;
  padding: 10px;
  height: 38px;
}


.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
  box-shadow: none;
  border-color: #959494 !important;
}


.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus{
  box-shadow: none;
  border-color: #959494 !important;
}

.ant-select-multiple .ant-select-selector{
  padding: 0 !important;
}
