.p-27{padding: 27px 40px;}
.py-27{padding: 27px 0;}
 
.heading1 {
    color: #313131;
    font-family: "Circular Std";
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    padding-bottom: 5px!important;
}

.px-40 {
    padding: 0 40px;
}
.bontinue_btn{
    border-radius: 10px;
    background: #29BCC1;
    width: 177px;
    height: 48px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.9px;}
    .posabs{position: absolute; bottom: 40px; right: 36px;}

.fs-16 {
    font-size: 16px!important;
}

 .radioNew label.ant-radio-wrapper {
    margin-right: 9px;
    margin-left: 5px;
}
 

.datepickerNew {position: relative;}

.datepickerNew .ant-picker.datepickerDate {

    width: 100%;
    height: 43px;
    border-radius: 6.605px;
    border: 0.5px solid #959494;
    padding: 0 15px;
    color: #313131;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background: #ff3e3e00;
    z-index: 9;
}

.datepickerNew .ant-picker.datepickerDate input {
    color: #313131;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.datepickerNew .ant-picker.datepickerDate input::placeholder { color: #313131;}
.iconClander path {
    fill: #D9D9D9;
}

.iconClander {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);    z-index: 0;
}

.datepickerNew  {
    margin-right: 38px;
}
 
 
textarea.textarea01 {
    border-radius: 6.605px;
    border: 0.5px solid #959494;
    height: 61px;
    color: #313131;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    box-shadow: initial;
    resize: none;background: #fff!important;
}
.character{
    position: absolute;
    bottom: 5px;
    right: 9px;
    color: #313131;
    font-family: "Circular Std";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.mainLabelClass p.labelText1 {
    color: #535151;
    font-family: "Circular Std";
    font-size: 16px!important;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    word-spacing: -4px;
}

.borderBox2.radioBox.newSpacing.radioNew label.ant-radio-wrapper span.ant-radio + *{
    padding-right: 7px;
    padding-left: 12.9px;
    font-family: "Circular Std";
}

/* .borderBox2.radioBox.newSpacing.radioNew  label.ant-radio-wrapper  span {
    font-family: "Circular Std";
}  */